/* eslint-disable react/no-array-index-key */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'

// Third Party
import styled, { css } from 'styled-components'

const TitleContainer = styled.div`
  h2 {
    font-size: ${props => props.theme.font.size.xxxxl};
  }

  @media screen and (max-width: 991px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    h2 {
      font-size: ${props => props.theme.font.size.xxxl};
    }

    img {
      justify-self: center;
      height: 350px;
    }
  }
`

const StyledImg = styled(Img)`
  @media screen and (max-width: 767px) {
    height: 300px;
  }
`

const WagonTitle = styled.h2`
  font-size: ${props => props.theme.font.size.xxxxl};

  @media screen and (max-width: 991px) {
    font-size: ${props => props.theme.font.size.xxxl};
    text-align: center;
  }
`

const Markten = styled.div`
  margin-top: 275px;

  @media screen and (max-width: 991px) {
    margin-top: 50px;
  }
`

const MarktenTemplate = ({
  data: {
    page: { 
      path,
      acf: {
        our_markets_group: ourMarketsGroup,
        our_wagons_group: ourWagonsGroup
      },
      yoastMeta },
  }
}) => {
  return (
    <Layout>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} article />
      <Markten className="container py-5">
        <div className="row">
          <TitleContainer className="col-lg-7">
            <h2 className="mb-0 font-family-secondary">{ourMarketsGroup.title}</h2>
            <img src={ourMarketsGroup.image.localFile.publicURL} alt="" />
          </TitleContainer>
          <div className="col-lg-5 pt-5">
            {ourMarketsGroup.days.map((day, index) => (
              <Day topline={index === 0} key={index} day={day} />
            ))}
          </div>
        </div>
        <div className="row py-5" />
        <WagonTitle className="font-family-secondary">{ourWagonsGroup.title}</WagonTitle>
        <div className="row pb-5">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-sm-6 pb-lg-0 pb-3">
                <StyledImg fluid={ourWagonsGroup.images[0].image.localFile.childImageSharp.fluid} alt="" />
              </div>
              <div className="col-sm-6 pb-lg-0 pb-3">
                <StyledImg fluid={ourWagonsGroup.images[1].image.localFile.childImageSharp.fluid} alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-sm-6 pb-lg-0 pb-3">
                <StyledImg fluid={ourWagonsGroup.images[2].image.localFile.childImageSharp.fluid} alt="" />
              </div>
              <div className="col-sm-6 pb-lg-0 pb-3">
                <StyledImg fluid={ourWagonsGroup.images[3].image.localFile.childImageSharp.fluid} alt="" />
              </div>
            </div>
          </div>
        </div>
      </Markten>
    </Layout>
  )
}

export default MarktenTemplate

const StyledDay = styled.div`
  position: relative;
  padding-bottom: 15px;

  ${props => props.topline && css`
    &::after {
      content: "";
      position: absolute;
      display: flex;
      top: 0;
      width: 420px;
      height: 2px;
      right: 0;
      left: 0;
      margin-right: auto;
      margin-left: auto;
      background-color: ${props.theme.color.main};

      @media screen and (max-width: 991px) {
        width: 70%;
      }
    }
  `}

  &::before {
    content: "";
    position: absolute;
    display: flex;
    bottom: 0;
    width: 420px;
    height: 2px;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    background-color: ${props => props.theme.color.main};

    @media screen and (max-width: 991px) {
      width: 70%;
    }
  }
`

const Day = ({ className, day, topline }) => (
  <StyledDay topline={topline} className={`d-flex justify-content-center flex-column ${className ? `${className}` : ``}`}>
    <h2 className="text-center font-family-secondary color-text-contrast font-size-xxml">{day.day}</h2>
    <p className="text-center mb-0 font-size-xm">{day.location}</p>
    <p className="text-center mb-0 font-size-xm">{day.sub_location}</p>
    <p className="text-center mb-0 font-size-m">{day.time}</p>
  </StyledDay>
)

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      acf {
        our_markets_group {
          title
          image {
            localFile {
              publicURL
            }
          }
          days {
            day
            location
            sub_location
            time
          }
        }
        our_wagons_group {
          title
          images {
            image {
              localFile {
                childImageSharp {
                  fluid(quality:100, maxWidth: 600) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
  }
`